import * as Yup from "yup";

const scheduleInitialValues = {
  title: "",
  scheduled: "",
  scheduledDate: "",
  message: "",
  trigger: [],
  triggerOnce: true,
};
const scheduleValidationSchema = Yup.object().shape({
  title: Yup.string().trim().required("Title is required*"),
  scheduled: Yup.string().trim(),
  scheduledDate: Yup.string().trim(),
  message: Yup.string().trim().required("Message is required*"),
  trigger: Yup.array(),

  triggerOnce: Yup.bool(),
});
export { scheduleInitialValues, scheduleValidationSchema };
