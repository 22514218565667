/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "../../routes/routePath";
import CustomTable from "../../components/table/customTable";
import UserListRow from "../../components/table/tableRows/userListRow";
import { userTableHeaderList } from "../../utils/seeds/tableHeaders";

import useGet from "../../hooks/useGet";
import ENDPOINTS from "../../services/endPoints";
import QUERY_KEYS from "../../services/queryKeys";
import userGlobalConfig from "../../utils/constants/globalConfig";

const UserListing = () => {
  const [countData, setCountData] = useState(15);
  const [pageNumber, setPageNumber] = useState(1);
  const nav = useNavigate();

  const {
    data: userListData,
    isLoading: isLoadingUsers,
    refetch: refetchUserList,
  } = useGet(ENDPOINTS.USER_LIST, QUERY_KEYS.GET_USER_LIST_QUERY_KEY, {
    page: pageNumber,
    limit: countData,
  });

  const handelView = (item) => {
    nav(RoutePath.USER_DETAIL.path, {
      state: { headerName: RoutePath.USER_DETAIL.title },
    });
    localStorage.setItem(userGlobalConfig.USER_ID, JSON.stringify(item?._id));
  };
  const handlePrevPage = () => {
    setPageNumber((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setPageNumber((nextPage) => nextPage + 1);
  };
  const handleRowLimitChange = (newValue) => {
    if (
      pageNumber === Math.ceil(userListData?.data?.totalRecords / countData)
    ) {
      setPageNumber(1);
    }
    setCountData(newValue);
  };
  useEffect(() => {
    refetchUserList();
  }, [pageNumber, countData]);

  return (
    <>
      <CustomTable
        isLoading={isLoadingUsers}
        heading="Users List"
        tableHeaderList={userTableHeaderList}
        handlePrevPage={handlePrevPage}
        handleNextPage={handleNextPage}
        handleRowLimitChange={handleRowLimitChange}
        currentPage={pageNumber}
        totalPages={userListData?.data?.totalRecords}
        countData={countData}
      >
        <UserListRow
          onView={handelView}
          data={userListData?.data?.usersList}
          isLoading={isLoadingUsers}
        />
      </CustomTable>
    </>
  );
};

export default UserListing;
