const icons = {
  searchIcon: "",
  mobile_Indicator: "/assets/images/mobile-indicator.svg",
  back_arrow: "/assets/images/back-arrow-icon.svg",
  shape_image: "/assets/images/shape-image.png",
  range_slider: "/assets/images/range-slider-1.png",
  shape_image_square: "/assets/images/squarImg.png",
  empty_image: "/assets/images/empty-image.jpg",
  back_btn: "/assets/images/back.png",
  quote_icon: "/assets/images/quote.svg",
  avatar_icon: "/assets/images/avatar_image.svg",
  rank_1: "/assets/images/rank_1.svg",
  rank_2: "/assets/images/rank_2.svg",
  rank_3: "/assets/images/rank_3.svg",
};

const logos = {
  appLogo: "",
};

const backgrounds = {};

const images = {};

const placeHolders = {};

const ImagesUrl = {
  ...icons,
  ...images,
  ...backgrounds,
  ...logos,
  ...placeHolders,
};

export default ImagesUrl;
