/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import ENDPOINTS from "../../services/endPoints";
import QUERY_KEYS from "../../services/queryKeys";
import usePost from "../../hooks/usePost";
import useGet from "../../hooks/useGet";
import { useFormik } from "formik";
import Select from "react-select";

import { toast } from "react-toastify";
import CustomModal from "../../components/customModal/customModal";
import SkillListingSkeleton from "../../utils/skeleton/skillListingSkeleton";
import NotFound from "../../components/notFound";
import useDelete from "../../hooks/useDelete";
import {
  scheduleInitialValues,
  scheduleValidationSchema,
} from "../../utils/validations/scheduleNotification";
import { WeekDayOptions } from "../../utils/seeds/weekDaysArray";

import { multiSelectCustomStyle } from "../../utils/helpers";

const ScheduleNotification = () => {
  const [deleteScheduleModal, setDeleteScheduleModal] = useState(false);
  const [deleteScheduleId, setDeleteScheduleId] = useState(null);
  const [triggerValue, setTriggerValue] = useState([]);
  const [utcTime, setUtcTime] = useState("");
  const [utcDate, setUtcDate] = useState("");
  const [scheduleModal, setScheduleModal] = useState({
    title: "",
    data: {},
    open: false,
    type: "",
    actionTitle: "",
    onConfirm: null,
  });

  const {
    mutateAsync: addScheduleNotificationMutateAsync,
    isLoading: isLoadingScheduleNotification,
  } = usePost(
    ENDPOINTS.SEND_SCHEDULE_NOTIFICATION,
    QUERY_KEYS.SEND_SCHEDULE_NOTIFICATION_QUERY_KEY
  );
  const {
    data: scheduleNotificationListData,
    isLoading: isLoadingScheduleNotificationList,
    refetch: refetchScheduleNotificationList,
  } = useGet(
    ENDPOINTS.LIST_SCHEDULE_NOTIFICATION,
    QUERY_KEYS.LIST_SCHEDULE_NOTIFICATION_QUERY_KEY
  );

  const scheduleListData =
    scheduleNotificationListData?.data?.scheduled_notifications;

  const {
    mutateAsync: editScheduleMutateAsync,
    isLoading: isLoadingEditSchedule,
  } = usePost(
    ENDPOINTS.EDIT_SCHEDULE_NOTIFICATION,
    QUERY_KEYS.EDIT_SCHEDULE_NOTIFICATION_QUERY_KEY
  );

  const {
    isLoading: isLoadingScheduleNotificationDelete,
    mutateAsync: scheduleNotificationDeleteMutateAsync,
  } = useDelete(
    ENDPOINTS.DELETE_SCHEDULE_NOTIFICATION,
    QUERY_KEYS.DELETE_SCHEDULE_NOTIFICATION_QUERY_KEY
  );
  const customValidate = (values) => {
    const errors = {};

    // When triggerOnce is true, validate scheduledDate
    if (values.triggerOnce === true) {
      if (!values.scheduledDate) {
        errors.scheduledDate = "scheduledDate is required*";
      }
    }

    // When triggerOnce is false, validate scheduled, Trigger, and Trigger length
    if (values.triggerOnce === false) {
      if (!values.scheduled) {
        errors.scheduled = "scheduled is required*";
      }

      if (!values?.trigger) {
        errors.trigger = "Trigger is required*"; // Custom message
      } else if (values?.trigger?.length < 1) {
        errors.trigger = "Trigger is required*";
      }
    }

    return errors;
  };

  const {
    errors,
    handleBlur,
    handleChange,
    touched,
    resetForm,
    handleSubmit,
    values,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: scheduleInitialValues,
    validationSchema: scheduleValidationSchema,
    validate: customValidate,
    onSubmit: async (values) => {
      if (scheduleModal.type === "Add") {
        const data = {
          title: values?.title,
          message: values?.message,
          trigger_status: values?.triggerOnce,
          time: utcTime,
          date: utcDate,
          week_day: values?.trigger,
        };
        try {
          const response = await addScheduleNotificationMutateAsync(data);
          if (response.statusCode === 200) {
            toast.success(response?.message);
            handleCloseModal();
            refetchScheduleNotificationList();
          } else {
            toast.error(response?.message);
          }
        } catch (error) {
          toast.error(error.response.data.message);
        }
      } else if (scheduleModal.type === "Edit") {
        const data = {
          _id: scheduleModal?.data?._id,
          title: values?.title,
          message: values?.message,
          time: utcTime,
          date: values.triggerOnce === false ? "" : utcDate,
          week_day: values.triggerOnce === true ? [] : values?.trigger,
          trigger_status: values?.triggerOnce,
        };
        try {
          const response = await editScheduleMutateAsync(data);
          if (response.statusCode === 200) {
            toast.success(response?.message);
            handleCloseModal();
            refetchScheduleNotificationList();
          } else {
            toast.error(response?.message);
          }
        } catch (error) {
          toast.error(error.response.data.message);
        }
      }
    },
  });
  console.log(values.triggerOnce, "values.triggerOnce");

  const handelDeleteSkillModal = (id) => {
    setDeleteScheduleId(id);
    setDeleteScheduleModal(true);
  };
  const onDeleteConfirm = async () => {
    try {
      const response = await scheduleNotificationDeleteMutateAsync({
        _id: deleteScheduleId,
      });
      if (response.status === true) {
        toast.success(response?.message);
        setDeleteScheduleModal(false);
        refetchScheduleNotificationList();
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  console.log(values, "values");

  const handleModalOpen = (e, item) => {
    if (e.target.title === "Add") {
      setScheduleModal({
        open: true,
        title: "Schedule Notification",
        actionTitle: "Add",
        type: e.target.title,
        onConfirm: handleSubmit,
        data: {},
      });
    } else if (e.target.title === "Edit") {
      setScheduleModal({
        open: true,
        title: "Edit Schedule Notification",
        actionTitle: "Edit",
        type: e.target.title,
        onConfirm: handleSubmit,
        data: item,
      });
    } else return null;
  };

  const triggerSelector = (e) => {
    const selectedTrigger = e.target.value;
    setFieldValue("trigger", [Number(selectedTrigger)]);
  };

  const filterDays = (dataArray, days) => {
    const dayValues = days?.map((item) => Number(item.day));
    return dataArray?.filter((item) => dayValues.includes(Number(item.value)));
  };
  const selectedDays = (item) => {
    setTriggerValue(
      WeekDayOptions?.filter((data) => item.includes(data.value))
    );
    setFieldValue("trigger", item);
  };
  const TimeZone = (value, date) => {
    // Convert local time to UTC
    if (value) {
      const [hours, minutes] = value.split(":").map(Number);
      const localDate = new Date();
      localDate.setHours(hours);
      localDate.setMinutes(minutes);
      localDate.setSeconds(0); // Optional: Set seconds to zero if not needed

      const utcHours = localDate.getUTCHours().toString().padStart(2, "0");
      const utcMinutes = localDate.getUTCMinutes().toString().padStart(2, "0");
      setUtcTime(`${utcHours}:${utcMinutes}`);
      setUtcDate(date);
      setFieldValue("scheduled", value);
      setFieldValue("scheduledDate", `${date}T${value}`);
    }
  };
  const convertToUTCDate = (dateTimeString) => {
    // Ensure the input is a valid ISO 8601 date-time string
    if (dateTimeString) {
      // Create a Date object from the provided ISO string (local time zone)
      const localDate = new Date(dateTimeString);

      // Get the UTC date components (year, month, day, hours, minutes)
      const utcYear = localDate.getUTCFullYear();
      const utcMonth = (localDate.getUTCMonth() + 1)
        .toString()
        .padStart(2, "0"); // Months are 0-indexed in JS, so we add 1
      const utcDay = localDate.getUTCDate().toString().padStart(2, "0");
      const utcHours = localDate.getUTCHours().toString().padStart(2, "0");
      const utcMinutes = localDate.getUTCMinutes().toString().padStart(2, "0");

      // Construct the UTC date-time string in ISO format (YYYY-MM-DDTHH:mm)
      // const utcDate = `${utcYear}-${utcMonth}-${utcDay}T${utcHours}:${utcMinutes}`;
      const utcDate = `${utcYear}-${utcMonth}-${utcDay}`;
      const utcTime = `${utcHours}:${utcMinutes}`;

      setUtcDate(utcDate);
      setUtcTime(utcTime);

      setFieldValue("scheduledDate", dateTimeString); // Store the original scheduled time
    }
  };

  const convertToLocalTime = (utcTimeString) => {
    if (!utcTimeString) return null;

    const [utcHours, utcMinutes] = utcTimeString.split(":").map(Number);
    const utcDate = new Date();
    utcDate.setUTCHours(utcHours);
    utcDate.setUTCMinutes(utcMinutes);
    utcDate.setUTCSeconds(0); // Optional: Set seconds to zero if not needed

    // Get local time components
    const localHours = utcDate.getHours().toString().padStart(2, "0");
    const localMinutes = utcDate.getMinutes().toString().padStart(2, "0");

    return `${localHours}:${localMinutes}`;
  };

  const returnModal = () => {
    if (scheduleModal.type === "Add") {
      return (
        <CustomModal
          open={scheduleModal.open}
          title={scheduleModal.title}
          actionTitle={scheduleModal.actionTitle}
          cancelTitle="Cancel"
          actionButtonClass="bg-danger"
          onConfirm={scheduleModal.onConfirm}
          isLoading={isLoadingScheduleNotification}
          onClose={handleCloseModal}
        >
          <div>
            <form>
              <div>
                <div className="mb-3 ">
                  <label>Title</label>
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="Enter Title"
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {touched.title && errors.title && (
                  <p className="error">{errors.title}</p>
                )}
                <div className="mb-3">
                  <label> Message </label>
                  <textarea
                    className="form-control form-control-lg"
                    placeholder="Enter  Message"
                    rows="3"
                    cols="50"
                    name="message"
                    value={values.message}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  ></textarea>
                </div>
                {touched.message && errors.message && (
                  <p className="error">{errors.message}</p>
                )}
                <div className="mb-3">
                  <label>Scheduled</label>
                  <input
                    type={
                      values.triggerOnce === true ? "datetime-local" : "time"
                    }
                    className="form-control form-control-lg"
                    placeholder="Enter Title"
                    name={
                      values.triggerOnce === true
                        ? "scheduledDate"
                        : "scheduled"
                    }
                    value={
                      values.triggerOnce === true
                        ? values.scheduledDate
                        : values.scheduled
                    }
                    onChange={(e) => {
                      const value = e.target.value;
                      values.triggerOnce === true
                        ? convertToUTCDate(value, "scheduledDate")
                        : TimeZone(value, "scheduled");
                    }}
                    onBlur={handleBlur}
                  />
                </div>

                {values.triggerOnce === true
                  ? touched.scheduledDate &&
                    errors.scheduledDate && (
                      <p className="error">{errors.scheduledDate}</p>
                    )
                  : touched.scheduled &&
                    errors.scheduled && (
                      <p className="error">{errors.scheduled}</p>
                    )}

                {values.triggerOnce === true ? null : (
                  <div className="mb-3">
                    <label> Trigger </label>
                    <select
                      className="form-control form-control-lg"
                      name="trigger"
                      value={values.trigger}
                      onChange={triggerSelector}
                      onBlur={handleBlur}
                    >
                      <option value={[]} disabled selected>
                        Select a day
                      </option>
                      {WeekDayOptions?.map((data, i) => {
                        return (
                          <option key={i} value={data.value}>
                            {data.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                )}

                {touched.trigger && errors.trigger && (
                  <p className="error">{errors.trigger}</p>
                )}
                <div className="form-check form-check-info text-start">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault"
                    checked={values.triggerOnce}
                    name="triggerOnce"
                    value={values.triggerOnce}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    Trigger{" "}
                    <a className="text-dark font-weight-bolder"> only once</a>
                  </label>
                </div>
              </div>
            </form>
          </div>
        </CustomModal>
      );
    } else if (scheduleModal.type === "Edit") {
      return (
        <CustomModal
          open={scheduleModal.open}
          title={scheduleModal.title}
          actionTitle={scheduleModal.actionTitle}
          cancelTitle="Cancel"
          actionButtonClass="bg-danger"
          onConfirm={scheduleModal.onConfirm}
          isLoading={isLoadingEditSchedule}
          onClose={handleCloseModal}
        >
          <div>
            <form>
              <div>
                <div className="mb-3 ">
                  <label> Title</label>
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="Enter Title"
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {touched.title && errors.title && (
                  <p className="error">{errors.title}</p>
                )}
                <div className="mb-3">
                  <label> Message </label>
                  <textarea
                    className="form-control form-control-lg"
                    placeholder="Enter  Message"
                    rows="3"
                    cols="50"
                    name="message"
                    value={values.message}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {touched.message && errors.message && (
                  <p className="error">{errors.message}</p>
                )}
                <div className="mb-3">
                  <label>Scheduled</label>
                  <input
                    type={
                      values.triggerOnce === true ? "datetime-local" : "time"
                    }
                    className="form-control form-control-lg"
                    placeholder="Enter Title"
                    name={
                      values.triggerOnce === true
                        ? "scheduledDate"
                        : "scheduled"
                    }
                    value={
                      values.triggerOnce === true
                        ? values.scheduledDate
                        : values.scheduled
                    }
                    onChange={(e) => {
                      const value = e.target.value;
                      values.triggerOnce === true
                        ? convertToUTCDate(value, "scheduledDate")
                        : TimeZone(value, "scheduled");
                    }}
                    onBlur={handleBlur}
                  />
                </div>

                {values.triggerOnce === true
                  ? touched.scheduledDate &&
                    errors.scheduledDate && (
                      <p className="error">{errors.scheduledDate}</p>
                    )
                  : touched.scheduled &&
                    errors.scheduled && (
                      <p className="error">{errors.scheduled}</p>
                    )}

                {values.triggerOnce === true ? null : (
                  <div className="mb-3">
                    <label> Trigger </label>
                    <select
                      className="form-control form-control-lg"
                      name="trigger"
                      value={values.trigger}
                      onChange={triggerSelector}
                      onBlur={handleBlur}
                    >
                      <option value={[]} disabled selected>
                        Select a day
                      </option>
                      {WeekDayOptions?.map((data, i) => {
                        return (
                          <option key={i} value={data.value}>
                            {data.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                )}

                {touched.trigger && errors.trigger && (
                  <p className="error">{errors.trigger}</p>
                )}
                <div className="form-check form-check-info text-start">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault"
                    checked={values.triggerOnce}
                    name="triggerOnce"
                    value={values.triggerOnce}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    Trigger{" "}
                    <a className="text-dark font-weight-bolder"> only once</a>
                  </label>
                </div>
              </div>
            </form>
          </div>
        </CustomModal>
      );
    } else return null;
  };

  const handleCloseModal = () => {
    setScheduleModal({
      open: false,
      data: {},
    });
    resetForm();
    setTriggerValue([]);
  };

  useEffect(() => {
    if (scheduleModal.type === "Edit") {
      setValues({
        title: scheduleModal?.data?.title,
        message: scheduleModal?.data?.message,

        triggerOnce: scheduleModal?.data?.trigger_status,
      });
      selectedDays(
        scheduleModal?.data?.week_day?.map((item) => Number(item.day))
      );
      TimeZone(
        convertToLocalTime(scheduleModal?.data?.time),
        scheduleModal?.data?.date
      );
    }
  }, [scheduleModal?.data]);

  return (
    <div>
      <div>
        <div className="row">
          <div className="col-md-12 mt-4 px-1">
            <div className="card">
              <div className="card-header pb-0 px-3">
                <div className="position-relative my-3 d-flex justify-center">
                  <h5 className="mb-0">Schedule Notification List</h5>
                  {isLoadingScheduleNotificationList ? null : (
                    <button
                      title="Add"
                      type="button"
                      className="btn btn-green text-uppercase position-absolute top-0 end-0 "
                      onClick={(e) => handleModalOpen(e)}
                    >
                      Send Notification
                    </button>
                  )}
                </div>
              </div>
              <div className="card-body pt-4 p-3">
                {isLoadingScheduleNotificationList ? (
                  <SkillListingSkeleton />
                ) : (
                  <ul className="list-group">
                    {!scheduleListData ? (
                      <NotFound content="Schedule Notification List Not Found" />
                    ) : (
                      scheduleListData?.map((item, index) => {
                        return (
                          <li
                            className="list-group-item border-0 p-4 mb-2 bg-gray-100 border-radius-lg"
                            key={index}
                          >
                            <div className="row justify-content-between gy-3">
                              <div className="card-flex d-flex cursor-pointer col-sm-10">
                                <div class="d-flex flex-column">
                                  <h6 class="mb-3 text-sm">{item.title}</h6>
                                  <span class="mb-2 text-xs">
                                    Message:
                                    <span class="text-dark ms-sm-2 font-weight-bold">
                                      {item.message}
                                    </span>
                                  </span>
                                  <span class="mb-2 text-xs">
                                    Scheduled:
                                    <span class="text-dark font-weight-bold ms-sm-2">
                                      {item.trigger_status === true
                                        ? `${item.date} ${convertToLocalTime(
                                            item.time
                                          )}`
                                        : convertToLocalTime(item.time)}
                                    </span>
                                  </span>
                                  {item.trigger_status === true ? null : (
                                    <span class="mb-2 text-xs">
                                      Trigger:
                                      <span class="text-dark ms-sm-2 font-weight-bold">
                                        {filterDays(
                                          WeekDayOptions,
                                          item.week_day
                                        )
                                          ?.map((item) => item.label)
                                          .join(", ")}
                                      </span>
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="col-sm-2 align-self-end ms-sm-auto text-end">
                                <a
                                  className="btn btn-link text-danger text-gradient px-3 mb-0"
                                  onClick={() =>
                                    handelDeleteSkillModal(item?._id)
                                  }
                                >
                                  <i className="far fa-trash-alt me-2" />
                                  Delete
                                </a>
                                <a
                                  title="Edit"
                                  className="btn btn-link text-dark px-3 mb-0"
                                  onClick={(e) => handleModalOpen(e, item)}
                                >
                                  <i
                                    className="fas fa-pencil-alt text-dark me-2"
                                    aria-hidden="true"
                                  />
                                  Edit
                                </a>
                              </div>
                            </div>
                          </li>
                        );
                      })
                    )}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {returnModal()}
      <CustomModal
        customClass="sm-modal"
        open={deleteScheduleModal}
        title={"Schedule Notification Delete"}
        actionTitle={"Delete"}
        cancelTitle={"Cancel"}
        actionButtonClass={"bg-danger"}
        onConfirm={onDeleteConfirm}
        isLoading={isLoadingScheduleNotificationDelete}
        onClose={() => setDeleteScheduleModal(false)}
      >
        <div>
          <span>Are you sure, you want to delete Schedule Notification?</span>
        </div>
      </CustomModal>
    </div>
  );
};

export default ScheduleNotification;
