/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ENDPOINTS from "../../services/endPoints";
import QUERY_KEYS from "../../services/queryKeys";
import usePost from "../../hooks/usePost";
import useGet from "../../hooks/useGet";
import usePut from "../../hooks/usePut";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import CustomModal from "../../components/customModal/customModal";
import SkillListingSkeleton from "../../utils/skeleton/skillListingSkeleton";
import NotFound from "../../components/notFound";
import {
  addSupportInitialValues,
  addSupportValidationSchema,
} from "../../utils/validations/support";
import Dropdown from "../../components/UI/dropdown";
import useDelete from "../../hooks/useDelete";

const Support = () => {
  const [deleteSupportModal, setDeleteSupportModal] = useState(false);
  const [deleteSupportId, setDeleteSupportId] = useState(null);

  const [supportModal, setSupportModal] = useState({
    title: "",
    data: {},
    open: false,
    type: "",
    actionTitle: "",
    onConfirm: null,
  });

  const handleChangeOptions = (event) => {
    setFieldValue("supportType", event.target.value);
  };

  const {
    mutateAsync: addSupportFAQMutateAsync,
    isLoading: isLoadingAddSupportFAQ,
  } = usePost(ENDPOINTS.ADD_SUPPORT_FAQ, QUERY_KEYS.ADD_SUPPORT_FAQ_QUERY_KEY);

  const {
    data: FAQlistData,
    isLoading: isLoadingFAQ,
    refetch: refetchFAQlist,
  } = useGet(ENDPOINTS.SUPPORT_FAQ, QUERY_KEYS.SUPPORT_FAQ_QUERY_KEY);

  const { data: supportTypeData } = useGet(
    ENDPOINTS.SUPPORT_TYPE,
    QUERY_KEYS.SUPPORT_TYPE_QUERY_KEY
  );

  const FAQData = FAQlistData?.data?.faq_list;
  const { mutateAsync: editFAQMutateAsync, isLoading: isLoadingEditFAQ } =
    usePut(ENDPOINTS.EDIT_SUPPORT_FAQ, QUERY_KEYS.EDIT_SUPPORT_FAQ_QUERY_KEY);

  const { isLoading: isLoadingFAQdelete, mutateAsync: FAQdeleteMutateAsync } =
    useDelete(
      ENDPOINTS.DELETE_SUPPORT_FAQ,
      QUERY_KEYS.DELETE_SUPPORT_FAQ_QUERY_KEY
    );

  const {
    errors,
    handleBlur,
    handleChange,
    touched,
    resetForm,
    handleSubmit,
    values,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: addSupportInitialValues,
    validationSchema: addSupportValidationSchema,
    onSubmit: async (values) => {
      if (supportModal.type === "Add") {
        const formData = {
          support_id: values?.supportType,
          question: values?.supportQuestion,
          answer: values?.supportAnswer,
        };
        try {
          const response = await addSupportFAQMutateAsync(formData);
          if (response.statusCode === 201) {
            toast.success(response?.message);
            handleCloseModal();
            refetchFAQlist();
          } else {
            toast.error(response?.message);
          }
        } catch (error) {
          toast.error(error.response.data.message);
        }
      } else if (supportModal.type === "Edit") {
        const formData = {
          _id: supportModal?.data?._id,
          support_id: values?.supportType,
          question: values?.supportQuestion,
          answer: values?.supportAnswer,
        };
        try {
          const response = await editFAQMutateAsync(formData);
          if (response.statusCode === 200) {
            toast.success(response?.message);
            handleCloseModal();
            refetchFAQlist();
          } else {
            toast.error(response?.message);
          }
        } catch (error) {
          toast.error(error.response.data.message);
        }
      }
    },
  });

  const handelDeleteSkillModal = (id) => {
    setDeleteSupportId(id);
    setDeleteSupportModal(true);
  };
  const onDeleteConfirm = async () => {
    try {
      const response = await FAQdeleteMutateAsync({
        _id: deleteSupportId,
      });
      if (response.status === true) {
        toast.success(response?.message);
        setDeleteSupportModal(false);
        refetchFAQlist();
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleModalOpen = (e, item) => {
    if (e.target.title === "Add") {
      setSupportModal({
        open: true,
        title: "Add Support Question and Answer",
        actionTitle: "Add",
        type: e?.target?.title,
        onConfirm: handleSubmit,
        data: {},
      });
    } else if (e.target.title === "Edit") {
      setSupportModal({
        open: true,
        title: "Edit Support Question and Answer",
        actionTitle: "Edit",
        type: e.target.title,
        onConfirm: handleSubmit,
        data: item,
      });
    } else return null;
  };

  const returnModal = () => {
    if (supportModal.type === "Add") {
      return (
        <CustomModal
          open={supportModal.open}
          title={supportModal.title}
          actionTitle={supportModal.actionTitle}
          cancelTitle={"Cancel"}
          actionButtonClass={"bg-danger"}
          onConfirm={supportModal.onConfirm}
          isLoading={isLoadingAddSupportFAQ}
          onClose={handleCloseModal}
        >
          <div>
            <form>
              <div>
                <div className="mb-3">
                  <label>Support Question Type</label>
                  <Dropdown
                    defaultValue={"Select Support Question Type"}
                    handleChange={handleChangeOptions}
                    options={supportTypeData?.data?.support_categories}
                    selectedOption={values?.supportType}
                  />
                </div>
                {touched.supportType && errors.supportType && (
                  <p className="error">{errors.supportType}</p>
                )}
                <div className="mb-3">
                  <label>Question</label>
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="Enter Question"
                    name="supportQuestion"
                    value={values.supportQuestion}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {touched.supportQuestion && errors.supportQuestion && (
                  <p className="error">{errors.supportQuestion}</p>
                )}
                <div className="mb-3">
                  <label>Answer</label>
                  <textarea
                    className="form-control form-control-lg"
                    placeholder="Enter Answer"
                    rows="3"
                    cols="50"
                    name="supportAnswer"
                    value={values.supportAnswer}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  ></textarea>
                </div>
                {touched.supportAnswer && errors.supportAnswer && (
                  <p className="error">{errors.supportAnswer}</p>
                )}
              </div>
            </form>
          </div>
        </CustomModal>
      );
    } else if (supportModal.type === "Edit") {
      return (
        <CustomModal
          open={supportModal.open}
          title={supportModal.title}
          actionTitle={supportModal.actionTitle}
          cancelTitle={"Cancel"}
          actionButtonClass={"bg-danger"}
          onConfirm={supportModal.onConfirm}
          isLoading={isLoadingEditFAQ}
          onClose={handleCloseModal}
        >
          <div>
            <form>
              <div>
                <div className="mb-3">
                  <label>Support Question Type</label>
                  <Dropdown
                    defaultValue={"Select Support Question Type"}
                    handleChange={handleChangeOptions}
                    options={supportTypeData?.data?.support_categories}
                    selectedOption={values?.supportType}
                  />
                </div>
                {touched.supportType && errors.supportType && (
                  <p className="error">{errors.supportType}</p>
                )}
                <div className="mb-3">
                  <label>Question</label>
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="Enter Question"
                    name="supportQuestion"
                    value={values.supportQuestion}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {touched.supportQuestion && errors.supportQuestion && (
                  <p className="error">{errors.supportQuestion}</p>
                )}
                <div className="mb-3">
                  <label>Answer</label>
                  <textarea
                    className="form-control form-control-lg"
                    placeholder="Enter Answer"
                    rows="3"
                    cols="50"
                    name="supportAnswer"
                    value={values.supportAnswer}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  ></textarea>
                </div>
                {touched.supportAnswer && errors.supportAnswer && (
                  <p className="error">{errors.supportAnswer}</p>
                )}
              </div>
            </form>
          </div>
        </CustomModal>
      );
    } else return null;
  };

  const handleCloseModal = () => {
    setSupportModal({
      open: false,
      data: {},
    });
    resetForm();
  };

  useEffect(() => {
    if (supportModal.type === "Edit") {
      setValues({
        supportType: supportModal?.data?.support_id,
        supportQuestion: supportModal?.data?.question,
        supportAnswer: supportModal?.data?.answer,
      });
    }
  }, [supportModal?.data]);

  return (
    <div>
      <div>
        <div className="row">
          <div className="col-md-12 mt-4 px-1">
            <div className="card">
              <div className="card-header pb-0 px-3">
                <div className="position-relative my-3 d-flex justify-center">
                  <h5 className="mb-0">Support</h5>
                  {isLoadingFAQ ? null : (
                    <button
                      title="Add"
                      type="button"
                      className="btn btn-green text-uppercase position-absolute top-0 end-0 "
                      onClick={(e) => handleModalOpen(e)}
                    >
                      Add Question
                    </button>
                  )}
                </div>
              </div>
              <div className="card-body pt-4 p-3">
                {isLoadingFAQ ? (
                  <SkillListingSkeleton />
                ) : (
                  <ul className="list-group">
                    {!FAQData ? (
                      <NotFound content="About Us List Not Found" />
                    ) : (
                      FAQData?.map((item, index) => {
                        return (
                          <li
                            className="list-group-item border-0 p-4 mb-2 bg-gray-100 border-radius-lg"
                            key={index}
                          >
                            <div className="row justify-content-between gy-3">
                              <div className="card-flex d-flex cursor-pointer col-sm-10">
                                <div className="item-content d-flex flex-column ">
                                  <h6 className="mb-3 text-lg">
                                    {item.question}
                                  </h6>
                                  <span className="mb-2 text-md">
                                    <span className="text-dark font-weight-normal">
                                      {item.answer}
                                    </span>
                                  </span>
                                </div>
                              </div>

                              <div className="col-sm-2 align-self-end ms-sm-auto text-end">
                                <a
                                  className="btn btn-link text-danger text-gradient px-3 mb-0"
                                  onClick={() =>
                                    handelDeleteSkillModal(item?._id)
                                  }
                                >
                                  <i className="far fa-trash-alt me-2" />
                                  Delete
                                </a>
                                <a
                                  title="Edit"
                                  className="btn btn-link text-dark px-3 mb-0"
                                  onClick={(e) => handleModalOpen(e, item)}
                                >
                                  <i
                                    className="fas fa-pencil-alt text-dark me-2"
                                    aria-hidden="true"
                                  />
                                  Edit
                                </a>
                              </div>
                            </div>
                          </li>
                        );
                      })
                    )}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {returnModal()}
      <CustomModal
        customClass="sm-modal"
        open={deleteSupportModal}
        title={"Question Delete"}
        actionTitle={"Delete"}
        cancelTitle={"Cancel"}
        actionButtonClass={"bg-danger"}
        onConfirm={onDeleteConfirm}
        isLoading={isLoadingFAQdelete}
        onClose={() => setDeleteSupportModal(false)}
      >
        <div>
          <span>Are you sure, you want to delete this Question?</span>
        </div>
      </CustomModal>
    </div>
  );
};

export default Support;
