const numberOnly = (event) => {
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
};

function truncateText(text) {
  if (text?.length > 80) {
    return `${text.substring(0, 80)} ..... `;
  } else {
    return text.substring(0, 80);
  }
}

const uniqueIdGenerator = function () {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
};
const multiSelectCustomStyle = {
  control: (base) => ({
    ...base,
    zIndex: 0,
    padding: "5px",
    border: "1.5px solid #c9ccd0",
    boxShadow: "none !important",
    "*": {
      boxShadow: "none !important",
    },
    "&:hover": {
      border: "1.5px solid #e0e0e0 !important",
    },
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: "14px",
    color: "#dbdbdb",
  }),
};

export { numberOnly, truncateText, uniqueIdGenerator, multiSelectCustomStyle };
