/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useRef, useState } from "react";
import { TemplateArray } from "../../utils/seeds/templateArray";
import TemplateIndex from "../templates";
import CustomModal from "../../components/customModal/customModal";
import { toast } from "react-toastify";
import {
  addStepInitialValues,
  addStepValidationSchema,
} from "../../utils/validations/step";
import { useFormik } from "formik";
import useGet from "../../hooks/useGet";
import ENDPOINTS from "../../services/endPoints";
import QUERY_KEYS from "../../services/queryKeys";
import userGlobalConfig from "../../utils/constants/globalConfig";
import useDelete from "../../hooks/useDelete";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "../../routes/routePath";
import usePost from "../../hooks/usePost";
import usePut from "../../hooks/usePut";
import DetailSkeleton from "../../utils/skeleton/detailsSkeleton";
import ImagesUrl from "../../utils/constants/images";
import { numberOnly } from "../../utils/helpers";

const StepDetail = () => {
  const [templateActive, setTemplateActive] = useState(0);
  const [editStepModal, setEditStepModal] = useState(false);
  const [editTemplate, setEditTemplate] = useState(false);
  const [uploadImg, setUploadImg] = useState(null);
  const [deleteStepModal, setDeleteStepModal] = useState(false);
  const [deleteStepId, setDeleteStepId] = useState(null);
  const uploadImageRef = useRef(null);
  const nav = useNavigate();
  const stepId = JSON.parse(localStorage.getItem(userGlobalConfig?.STEP_ID));
  const subStepId = JSON.parse(
    localStorage.getItem(userGlobalConfig?.SUB_STEP_ID)
  );
  const {
    data: stepDetailData,
    isLoading: isLoadingStepDetail,
    isFetching: isFetchingStepDetail,
    refetch: refetchStepDetail,
  } = useGet(ENDPOINTS.STEPS_DETAIL, QUERY_KEYS.STEPS_DETAIL_QUERY_KEY, {
    _id: stepId,
  });
  const { mutateAsync: editStepMutateAsync, isLoading: isLoadingEditStep } =
    usePut(ENDPOINTS.STEPS_EDIT, QUERY_KEYS.STEPS_EDIT_QUERY_KEY);
  const { isLoading: isLoadingStepDelete, mutateAsync: StepDeleteMutateAsync } =
    useDelete(ENDPOINTS.STEPS_DELETE, QUERY_KEYS.STEPS_DELETE_QUERY_KEY);
  const { isLoading: isLoadingStepStatus, mutateAsync: stepStatusMutateAsync } =
    usePost(ENDPOINTS.STEPS_STATUS, QUERY_KEYS.STEPS_STATUS_QUERY_KEY);

  const stepData = stepDetailData?.data;
  const subStepData = stepData?.substeps?.find(
    (item) => item?._id == subStepId
  );

  const handelActiveTemplate = (index) => {
    setTemplateActive(index);
    window.scrollTo(0, 700);
  };
  const {
    errors,
    handleBlur,
    handleChange,
    touched,
    resetForm,
    handleSubmit,
    values,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: addStepInitialValues,
    validationSchema: addStepValidationSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("step_id", stepId);
      formData.append("sub_step_id", subStepId);
      formData.append("name", values?.stepName);
      formData.append("points", values?.stepPoints);
      formData.append("description", values?.stepDescription);
      formData.append("image", values?.stepImage);
      try {
        const response = await editStepMutateAsync(formData);
        if (response.statusCode === 200) {
          toast.success(response?.message);
          setEditStepModal(false);
          resetForm();
          refetchStepDetail();
        } else {
          toast.error(response?.message);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    },
  });
  const onChangeFile = (target) => {
    const selectedFile = target.files[0];
    setUploadImg(URL.createObjectURL(selectedFile));
    setFieldValue("stepImage", selectedFile);
    target.value = "";
  };
  const handleSelectUploadImage = () => {
    if (uploadImageRef.current) {
      uploadImageRef.current.click();
    }
  };
  const handelRemoveUploadImg = () => {
    setUploadImg(null);
    setFieldValue("stepImage", "");
  };
  const handelDeleteStepModal = (id) => {
    setDeleteStepId(id);
    setDeleteStepModal(true);
  };
  const onDeleteConfirm = async () => {
    try {
      const response = await StepDeleteMutateAsync({
        step_id: stepId,
        sub_step_id: deleteStepId,
      });
      if (response.status === true) {
        toast.success(response?.message);
        setDeleteStepModal(false);
        nav(RoutePath.SKILL_DETAIL.path);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  const handleStatusChange = async (item) => {
    try {
      let statusCode = item.status === 1 ? "2" : "1";
      const response = await stepStatusMutateAsync({
        step_id: stepId,
        sub_step_id: item?._id,
        status: statusCode,
      });
      if (response.status === true) {
        toast.success(response?.message);
        refetchStepDetail();
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };
  useEffect(() => {
    setValues({
      stepName: subStepData?.name,
      stepPoints: subStepData?.points,
      stepDescription: subStepData?.description,
      stepImage: subStepData?.image,
    });
  }, [stepData, editStepModal]);

  useEffect(() => {
    if (subStepData?.template_id) {
      setTemplateActive(subStepData?.template_id);
      // setEditTemplate(isFetchingStepDetail ? true : true);
      setEditTemplate(true);
    } else {
      setEditTemplate(true);
      setTemplateActive(0);
      // setEditTemplate(true);
    }
  }, [subStepData]);

  const handleEditForm = (status) => {
    setEditTemplate(status ?? !editTemplate);
  };
  return (
    <div>
      <div className="row px-3">
        {isLoadingStepDetail ? (
          <DetailSkeleton />
        ) : (
          <div className="card position-relative">
            <img
              className="back_btn"
              src={ImagesUrl.back_btn}
              alt=".."
              onClick={() =>
                nav(RoutePath.SKILL_DETAIL.path, {
                  state: { headerName: RoutePath.SKILL_DETAIL.title },
                })
              }
            />
            <div className="card-body">
              <div className="row gy-3">
                <div className="col-12">
                  <div className="d-flex justify-content-end align-items-center gap-1">
                    <i
                      className="fas fa-pencil-alt text-dark px-1 cursor-pointer"
                      onClick={() => setEditStepModal(true)}
                    ></i>
                    <i
                      className="far fa-trash-alt  text-danger px-1 cursor-pointer "
                      onClick={() => handelDeleteStepModal(subStepData?._id)}
                    ></i>
                    <span className="toggle-box px-1">
                      <input
                        onClick={() => handleStatusChange(subStepData)}
                        type="checkbox"
                        checked={subStepData?.status === 1 ? true : false}
                        id={1}
                        disabled={isLoadingStepStatus}
                      />
                      <label className="m-0" htmlFor={1} />
                    </span>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-sm-4 col-lg-3">
                      <div className="full-image-blk">
                        <img src={subStepData?.image} alt="Image" />
                      </div>
                    </div>
                    <div className="col-sm-8 col-lg-9">
                      <div className="content-blk">
                        <h1 className="h2">{subStepData?.name}</h1>
                        <p>{subStepData?.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {editTemplate ? (
        <div className="card-wrapper">
          <div className="row">
            <div className="col-12">
              <div className="flex-start-btn mb-4">
                <h2 className="h5">Select Template</h2>
              </div>
            </div>
          </div>
          <div className="row row-cols-2 row-cols-sm-2 row-cols-lg-5 row-cols-xl-6 gy-4">
            {TemplateArray?.map((item, index) => {
              return (
                <div
                  className="col cursor-pointer"
                  key={index}
                  onClick={() => handelActiveTemplate(index)}
                >
                  <div
                    className={templateActive == index ? "card active" : "card"}
                  >
                    <div className="card-header  text-center">
                      <div className="icon icon-shape icon-sm bg-gradient-primary shadow text-center border-radius-sm">
                        {item.icon}
                      </div>
                    </div>
                    <div className="card-body p-2 m-0 text-center">
                      <h6 className="text-center ">{item.name}</h6>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}

      <TemplateIndex
        index={templateActive}
        data={subStepData}
        stepId={stepId}
        handleEditForm={handleEditForm}
        editTemplate={editTemplate}
        dataLoading={isLoadingStepDetail}
        refetchStepDetail={refetchStepDetail}
      />

      <CustomModal
        open={editStepModal}
        title={"Edit Step"}
        actionTitle={"Edit"}
        cancelTitle={"Cancel"}
        actionButtonClass={"bg-danger"}
        onConfirm={handleSubmit}
        isLoading={isLoadingEditStep}
        onClose={() => {
          setEditStepModal(false);
          resetForm();
        }}
      >
        <div>
          <form>
            <div className="mb-3">
              <label> Name</label>
              <input
                type="text"
                className="form-control form-control-lg"
                placeholder="Enter Step Name"
                name="stepName"
                value={values.stepName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            {touched.stepName && errors.stepName && (
              <p className="error">{errors.stepName}</p>
            )}
            <div className="mb-3">
              <label> Point</label>
              <input
                type="text"
                className="form-control form-control-lg"
                placeholder="Enter Step Point"
                name="stepPoints"
                value={values.stepPoints}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyPress={numberOnly}
              />
            </div>
            {touched.stepPoints && errors.stepPoints && (
              <p className="error">{errors.stepPoints}</p>
            )}
            <div className="mb-3">
              <label> Description </label>
              <textarea
                className="form-control form-control-lg"
                placeholder="Enter Step Description"
                rows="3"
                cols="50"
                name="stepDescription"
                value={values.stepDescription}
                onChange={handleChange}
                onBlur={handleBlur}
              ></textarea>
            </div>
            {touched.stepDescription && errors.stepDescription && (
              <p className="error">{errors.stepDescription}</p>
            )}

            <div className="drag-input-field mt-3">
              <label>Upload Image </label>
              <input
                type="file"
                accept="image/*"
                name="stepImage"
                onBlur={handleBlur}
                onChange={({ target }) => {
                  onChangeFile(target);
                }}
                ref={uploadImageRef}
              />
              {values?.stepImage?.length === 0 ? (
                <label className="m-0" onClick={handleSelectUploadImage}>
                  <span className="upload-text">
                    <i className="fa-solid fa-cloud-arrow-up" />
                    Upload image
                  </span>
                </label>
              ) : (
                <div className="position-relative border-1 border border-gray rounded-2 d-flex justify-content-center p-2">
                  <img
                    src={uploadImg === null ? values.stepImage : uploadImg}
                    style={{
                      height: "200px",
                      width: "200px",

                      objectFit: "contain",
                      borderRadius: "5px",
                      border: "solid 1px #8080808a",
                    }}
                    alt="..."
                  />
                  <button
                    className="removeimg"
                    type="button"
                    onClick={() => handelRemoveUploadImg()}
                  >
                    X
                  </button>
                </div>
              )}
            </div>
            {touched.stepImage && errors.stepImage && (
              <p className="error" style={{ marginTop: "4px" }}>
                {errors.stepImage}
              </p>
            )}
          </form>
        </div>
      </CustomModal>
      <CustomModal
        customClass="sm-modal"
        open={deleteStepModal}
        title={"Step Delete"}
        actionTitle={"Delete"}
        cancelTitle={"Cancel"}
        actionButtonClass={"bg-danger"}
        onConfirm={onDeleteConfirm}
        isLoading={isLoadingStepDelete}
        onClose={() => setDeleteStepModal(false)}
      >
        <div>
          <span>Are you sure, you want to delete Step?</span>
        </div>
      </CustomModal>
    </div>
  );
};

export default StepDetail;
